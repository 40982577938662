import { Button, useMediaQuery, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { validateAndCreateService } from '../../../../../api/caseCore';
import pausedRequestImage from '../../../../../assets/images/assistanceRequest/pausedRequestImage.svg';
import successRequestImage from '../../../../../assets/images/assistanceRequest/successRequestImage.svg';
import Spinner from '../../../../../components/Spinner';
import {
  ESTATE_MATERIAL,
  PERSON_MATERIAL,
  PET_MATERIAL,
  VEHICLE_MATERIAL,
} from '../../../../../constants/constants';
import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { clearRequest } from '../../../../../redux/actions/requestActions';
import { getRequestSelector } from '../../../../../redux/selectors';
import { handleContactPhoneNumber, scrollTo } from '../../../../../util/commons';
import Field from '../RequestView/CardsTypeMaterial/Materials/Field/Field';
import CardSumary from './CardSumary/CardSumary';
import { mapCostConcepts } from './costConceptMapper';
import { getServiceFormQuestionsAnswers } from './serviceFormMapper';
import { useStyles } from './styles';

interface SummaryViewProps {
  handleBack: any;
  onServiceCreated: any;
  setStepperHidden: (hidden: boolean) => void;
  immediateRequest: boolean;
  setValue: any;
}

const SummaryView: React.FC<SummaryViewProps> = ({
  handleBack,
  onServiceCreated,
  setStepperHidden,
  immediateRequest,
  setValue,
}) => {
  const classes = useStyles();
  const {
    selectedCard,
    materialSelected,
    serviceName,
    descriptionData,
    serviceId,
    planSelected,
    serviceCategory,
    caseNumber,
    costConcepts,
    dynamicForm,
  } = useSelector(getRequestSelector);
  const { dateFormatCode, timezoneIdName, businessUnitUUID, tenantUUID, portalConfigParams } =
    useContext(BusinessUnitParamsContext);

  const { contact_phone } = portalConfigParams!;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceCreated, setIsServiceCreated] = useState(false);
  const [showPausedRequest, setShowPausedRequest] = useState(false);
  const [bookingId, setBookingId] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const navigateToHome = () => {
    setStepperHidden(false);
    history.push('/');
    dispatch(clearRequest());
  };

  useEffect(() => {
    scrollTo();
  }, []);

  const getZonedDateTime = (
    isImmediateRequest: boolean,
    timezoneIdName: string | undefined,
    date: string,
    hour: string
  ): string => {
    if (isImmediateRequest) {
      return moment(date + ' ' + hour)
        .tz(timezoneIdName || '')
        .format();
    }

    const formattedDate: string = moment(date + ' ' + hour).format();

    return formattedDate
      .toString()
      .substring(0, formattedDate.length - 6)
      .concat(
        moment(date)
          .tz(timezoneIdName || '')
          .format('Z')
      );
  };

  const handleNext = () => {
    let materialTypeId: number;

    switch (selectedCard) {
      case VEHICLE_MATERIAL:
        materialTypeId = 1;
        break;
      case ESTATE_MATERIAL:
        materialTypeId = 2;
        break;
      case PERSON_MATERIAL:
        materialTypeId = 3;
        break;
      default:
        materialTypeId = 4;
    }

    const formattedDate = getZonedDateTime(
      immediateRequest,
      timezoneIdName,
      descriptionData.date,
      descriptionData.time
    );

    const getAddressDestination = (descriptionData: any): any => {
      if (descriptionData?.directionDestination) {
        return {
          destinationAddress: descriptionData.directionDestination.name,
          latDestination: descriptionData.directionDestination.position.lat,
          longDestination: descriptionData.directionDestination.position.lng,
        };
      }

      return {
        destinationAddress: '',
        latDestination: '',
        longDestination: '',
      };
    };

    const payload = {
      applicant: {
        email: descriptionData.email,
        mobilePhone: descriptionData.phoneNumber,
      },
      booking: {
        address: {
          lat: descriptionData.directionOrigen.position.lat,
          lng: descriptionData.directionOrigen.position.lng,
          principal: descriptionData.directionOrigen.name,
        },
        addressDestination: getAddressDestination(descriptionData),
        businessUnitId: businessUnitUUID,
        caseId: caseNumber,
        communeId: descriptionData.commune.value,
        communeName: descriptionData.commune.label,
        costConceptBookingRequest: mapCostConcepts(costConcepts, descriptionData),
        immediate: immediateRequest,
        date: formattedDate,
        regionId: descriptionData.region.value,
        regionName: descriptionData.region.label,
        servicePayload: {
          serviceFantasyCategoryName: serviceCategory,
          serviceFantasyName: serviceName,
          serviceId: serviceId,
        },
      },
      planSubscription: {
        coverageName: (planSelected as any).coverages[0].coverageName,
        materialId: materialSelected.id,
        materialIdentifier: getMaterialIdentifier(materialTypeId),
        materialTypeId: materialTypeId,
        planId: (planSelected as any).planId,
        planName: (planSelected as any).planName,
        planSubscriptionId: (planSelected as any).planSubscriptionId,
        subscriptionId: (planSelected as any).subscriptionId,
      },
      tenantUnitId: tenantUUID,
      serviceForm: {},
    };

    if (!payload.serviceForm) {
      payload.serviceForm = {
        serviceFormAnswers: [],
      };
    }

    (payload.serviceForm as any).formId = (dynamicForm as any).questionnaireUUID;
    (payload.serviceForm as any).versionId = (dynamicForm as any).version;
    (payload.serviceForm as any).serviceFormAnswers = getServiceFormQuestionsAnswers(
      dynamicForm as any
    );

    const createService = async () => {
      try {
        setIsLoading(true);
        const serviceCreated = validateAndCreateService(businessUnitUUID, payload);
        serviceCreated
          .then((result) => {
            const bookingId = result.bookingId.toString();

            setShowPausedRequest(false);
            setStepperHidden(true);
            setIsServiceCreated(true);
            onServiceCreated(true);
            setBookingId(bookingId);
          })
          .catch((error) => {
            handleCreateServiceError(error);
          });
        setIsLoading(false);
      } catch (error) {
        history.push('/error-page');
      }
    };

    createService();

    setValue('region', '');
    setValue('commune', '');
  };

  const handleCreateServiceError = (error: AxiosError) => {
    const errorMessage = error.response?.data?.error;
    const errorCode = error.response?.data?.status;
    const genericErrorMessage =
      'El servicio no ha podido ser creado por el portal por una falla del sistema';

    if (
      (errorMessage && errorMessage === genericErrorMessage) ||
      (errorCode && errorCode === 500)
    ) {
      setShowPausedRequest(false);
      console.error('Error al crear el servicio:', error);
      history.push('/error-page');
    } else {
      setStepperHidden(true);
      setShowPausedRequest(true);
    }
  };

  const getSelectedMaterialTitle = () => {
    if (selectedCard === VEHICLE_MATERIAL) {
      return 'Vehículo seleccionado';
    }
    if (selectedCard === ESTATE_MATERIAL) {
      return 'Inmueble seleccionado';
    }
    if (selectedCard === PET_MATERIAL) {
      return 'Mascota seleccionada';
    }
    if (selectedCard === PERSON_MATERIAL) {
      return 'Persona seleccionada';
    } else {
      return '';
    }
  };

  const getMaterialIdentifier = (materialTypeId: number) => {
    if (materialTypeId == 1) {
      const patent = materialSelected.patentPlate ? materialSelected.patentPlate : '-';
      const vin = materialSelected.vin ? materialSelected.vin : '-';
      const engineNumber = materialSelected.engineNumber ? materialSelected.engineNumber : '-';
      return `${patent}/${vin}/${engineNumber}`;
    }

    if (materialTypeId == 2) {
      return materialSelected.address;
    }

    if (materialTypeId == 3) {
      return materialSelected.identificationNumber;
    }

    if (materialTypeId == 4) {
      return materialSelected.petName;
    }
  };

  const getSelectedMaterial = () => {
    if (selectedCard === VEHICLE_MATERIAL) {
      return (
        <>
          <Field field={'Marca: '} value={materialSelected.brand} />
          <Field field={'Modelo: '} value={materialSelected.model} />
          <Field field={'Color: '} value={materialSelected.color} />
          <Field field={'Patente: '} value={materialSelected.patentPlate} />
          <Field
            field={'VIN/Motor: '}
            value={`${materialSelected.vin ? materialSelected.vin : '-'}/${
              materialSelected.engineNumber ? materialSelected.engineNumber : '-'
            }`}
          />
        </>
      );
    }
    if (selectedCard === ESTATE_MATERIAL) {
      return (
        <>
          <Field
            field={'Tipo: '}
            value={materialSelected.homeTypeId === 2 ? 'Departamento' : 'Casa'}
          />
          <Field field={'Dirección: '} value={materialSelected.address} />
          <Field field={'Numeración: '} value={materialSelected.postalCode} />
        </>
      );
    }
    if (selectedCard === PET_MATERIAL) {
      return (
        <>
          <Field field={'Nombre: '} value={materialSelected.petName} />
          <Field field={'Tipo: '} value={materialSelected.type} />
          <Field field={'Raza: '} value={materialSelected.petBreed} />
        </>
      );
    }
    if (selectedCard === PERSON_MATERIAL) {
      return (
        <>
          <Field field={'Nombre: '} value={materialSelected.firstName} />
          <Field field={'Apellido: '} value={materialSelected.lastName} />
          <Field field={'Tipo de documento: '} value={materialSelected.identificationType} />
          <Field field={'No. documento: '} value={materialSelected.identificationNumber} />
        </>
      );
    }
  };

  const getPausedRequestButton = () => {
    return (
      <Button
        onClick={navigateToHome}
        style={{ textTransform: 'none' }}
        classes={{ root: classes.pausedRequestButton }}
      >
        {'Volver al inicio'}
      </Button>
    );
  };

  const handleGoToBookingTracking = () => {
    history.push(`/validate-booking/${bookingId}`);
  };

  return (
    <>
      {isLoading && (
        <div className={classes.overlay}>
          <Spinner />
        </div>
      )}
      <div className={classes.container}>
        {isServiceCreated && (
          <>
            <div className={classes.imageContainer}>
              <img className={classes.requestImage} src={successRequestImage} alt="Exito" />
            </div>
            <div className={classes.requestTitle}>{'¡Solicitud realizada con éxito!'}</div>
            <CardSumary title={'Solicitud'}>
              <Field field={'No. caso: '} value={caseNumber} />
              <Field field={'No. servicio: '} value={bookingId} />
            </CardSumary>
          </>
        )}

        {showPausedRequest && (
          <>
            <div className={classes.imageContainer}>
              <img className={classes.requestImage} src={pausedRequestImage} alt="Paused" />
            </div>
            <div className={classes.requestTitle}>{'Solicitud pausada'}</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.pausedRequestSubtitle}>
                {`Tu solicitud no ha podido ser completada porque existe un cobro asociado. Si deseas continuar, contáctate con nosotros al `}
                <span className={classes.pausedRequestContactPhone}>{`${handleContactPhoneNumber(
                  contact_phone
                )}`}</span>
                {'.'}
              </div>
            </div>
            <CardSumary title={'Solicitud'}>
              <Field field={'No. caso: '} value={caseNumber} />
            </CardSumary>
          </>
        )}

        <CardSumary title={'Servicio a solicitar'}>
          <div className={classes.serviceTitle}>{serviceName}</div>
        </CardSumary>
        <CardSumary title={getSelectedMaterialTitle()}>{getSelectedMaterial()}</CardSumary>
        <CardSumary title={'Detalle de la solicitud'}>
          <Field field={'Origen: '} value={descriptionData?.directionOrigen?.name} />
          <Field field={'Destino: '} value={descriptionData?.directionDestination?.name} />
          <Field
            field={'Fecha deseada: '}
            value={moment(descriptionData?.date)
              .tz(timezoneIdName || '')
              .format(dateFormatCode)}
          />
          <Field field={'Hora deseada: '} value={descriptionData?.time} />
          <Field
            field={'Teléfono: '}
            value={handleContactPhoneNumber(descriptionData?.phoneNumber)}
          />
          <Field field={'Correo electrónico: '} value={descriptionData?.email} />
        </CardSumary>
        <div className={classes.buttons}>
          {isServiceCreated ? (
            <>
              {isMobile ? (
                <>
                  <Button
                    style={{ textTransform: 'none' }}
                    classes={{ root: classes.buttonFollow }}
                    onClick={handleGoToBookingTracking}
                  >
                    Quiero hacer seguimiento a mi solicitud
                  </Button>
                  <Button
                    onClick={navigateToHome}
                    style={{ textTransform: 'none' }}
                    classes={{ root: classes.buttonCancelMB }}
                  >
                    Volver al inicio
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={navigateToHome}
                    style={{ textTransform: 'none' }}
                    classes={{ root: classes.buttonCancel }}
                  >
                    Volver al inicio
                  </Button>
                  <Button
                    style={{ textTransform: 'none' }}
                    classes={{ root: classes.buttonFollow }}
                    onClick={handleGoToBookingTracking}
                  >
                    Quiero hacer seguimiento a mi solicitud
                  </Button>
                </>
              )}
            </>
          ) : showPausedRequest ? (
            getPausedRequestButton()
          ) : (
            <>
              <Button
                onClick={handleBack}
                style={{ textTransform: 'none' }}
                classes={{ root: classes.buttonCancel }}
              >
                Modificar solicitud
              </Button>
              <Button
                onClick={handleNext}
                style={{ textTransform: 'none' }}
                classes={{ root: classes.buttonSubmit }}
              >
                Continuar solicitud
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SummaryView;
